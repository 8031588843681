import { useI18n } from 'nordic/i18n';
import { Typography } from '@andes/typography';
import { Button } from '@andes/button';

import Icon from '@app/components/Icons';

export type Props = {
  urlHome: string;
};

export const getServerSideProps: NordicPage.ServerSidePropsFunction = (
  req,
) => ({
  props: {
    urlHome: `${req.protocol}://${String(req.platform?.domain)}`,
  },
});

const ViewDefault = ({ urlHome }: Props) => {
  const { i18n } = useI18n();

  return (
    <section className="error-page__container">
      <div className="error-page__content">
        <Icon id="error" />
        <Typography component="h2" size="xs">
          {i18n.gettext('Por seguridad, no puedes acceder a este link.')}
        </Typography>
        <Button hierarchy="transparent" href={urlHome}>
          {i18n.gettext('Ir al Inicio')}
        </Button>
      </div>
    </section>
  );
};

export const hydrate = 'hydrateRoot';

export default ViewDefault;
